import Layout from "@/layout";

export default {
   Layout,
  'dashboard-console': () => import('@/view/sys/console/index.vue'),
  'dashboard-workspace': () => import('@/view/sys/space/index.vue'),
  'result-success': () => import('@/view/sys/result/success.vue'),
  'result-failure': () => import('@/view/sys/result/failure.vue'),
  'error-403': () => import('@/view/sys/error/403.vue'),
  'error-404': () => import('@/view/sys/error/404.vue'),
  'error-500': () => import('@/view/sys/error/500.vue'),
  'user': () => import('@/view/sys/user/index.vue'),
  'role': () => import('@/view/sys/role/index.vue'),
  'log-oper': () => import('@/view/sys/log/oper.vue'),
  'log-auth': () => import('@/view/sys/log/auth.vue'),
  'power': () => import('@/view/sys/power/index.vue'),
  'config': () => import('@/view/sys/config/index.vue'),
  'dict': () => import('@/view/sys/dict/index.vue'),
  'post': () => import('@/view/sys/post/index.vue'),
  'dept-list': () => import('@/view/sys/dept/index.vue'),
  'server': () => import('@/view/sys/server/index.vue'),
  'online': () => import('@/view/sys/online/index.vue'),
  'redis': () => import('@/view/sys/redis/index.vue'),
  'doc': () => import('@/view/sys/doc/index.vue'),
  'mail': () => import('@/view/sys/mail/index.vue'),
  'oss': () => import('@/view/sys/oss/index.vue'),
  'dept': () => import('@/view/sys/dept/index.vue'),
  'dataSource': () => import('@/view/sys/dataSource/index.vue'),
  'job': () => import('@/view/sys/job/index.vue'),
  'jobLog': () => import('@/view/sys/jobLog/index.vue'),
  'announce': () => import('@/view/sys/announce/index.vue'),
  'inbox': () => import('@/view/sys/inbox/index.vue'),
  'tenant': () => import('@/view/sys/tenant/index.vue'),
  'sms': () => import('@/view/sys/sms/index.vue'),

  'area': () => import('@/view/iot/area/index.vue'),
  'device': () => import('@/view/iot/device/index.vue'),
  'grid': () => import('@/view/iot/grid/index.vue'),
  'record': () => import('@/view/iot/record/index.vue'),
  'token': () => import('@/view/iot/token/index.vue'),
}
