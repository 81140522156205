<template>
  <div class="page-footer">
    <div class="page-footer-copyright" style="text-align: center">Copyright © 2022 &nbsp;&nbsp; 物联网设备管理平台 V1.0</div>
  </div>
</template>
<script>
import "./index.less";
export default {
  name: "page-footer"
};
</script>
